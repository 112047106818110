import { OptionSelect } from '../models/option-select';
import { enumToArray } from '../utils/enum-to-array';

/** Subcontractor category. */
export enum SubcontractorCategory {

  /** Default category. */
  Other = 'Other',
  Architect = 'Architect',
  Commissioning = 'Commissioning',
  ConsultantMEP = 'ConsultantMEP',
  ConsultantOther = 'ConsultantOther',
  Controls = 'Controls',
  ElectricalContractorControls = 'ElectricalContractorControls',
  ElectricalContractorFireAlarm = 'ElectricalContractorFireAlarm',
  ElectricalContractorLighting = 'ElectricalContractorLighting',
  ElectricalContractorOther = 'ElectricalContractorOther',
  ElectricalContractorSecurity = 'ElectricalContractorSecurity',
  ElectricalContractorLineVoltage = 'ElectricalContractorLineVoltage',
  Energy = 'Energy',
  WindowAndDoorContractor = 'WindowAndDoorContractor',
  GeneralContractor = 'GeneralContractor',
  MechanicalContractor = 'MechanicalContractor',
  RoofingContractor = 'RoofingContractor',
  TAB = 'TAB',
}

export namespace SubcontractorCategory {
  const TO_TITLE_MAP: Record<SubcontractorCategory, string> = {
    [SubcontractorCategory.Other]: 'Other',
    [SubcontractorCategory.Architect]: 'Architect',
    [SubcontractorCategory.Commissioning]: 'Commissioning',
    [SubcontractorCategory.ConsultantMEP]: 'Consultant-MEP',
    [SubcontractorCategory.ConsultantOther]: 'Consultant-Other',
    [SubcontractorCategory.Controls]: 'Controls',
    [SubcontractorCategory.ElectricalContractorControls]: 'Electrical Contractor-Controls',
    [SubcontractorCategory.ElectricalContractorFireAlarm]: 'Electrical Contractor-Fire Alarm',
    [SubcontractorCategory.ElectricalContractorLighting]: 'Electrical Contractor-Lighting',
    [SubcontractorCategory.ElectricalContractorOther]: 'Electrical Contractor-Other',
    [SubcontractorCategory.ElectricalContractorSecurity]: 'Electrical Contractor-Security',
    [SubcontractorCategory.ElectricalContractorLineVoltage]: 'Electrical Contractor-Line Voltage',
    [SubcontractorCategory.Energy]: 'Energy',
    [SubcontractorCategory.WindowAndDoorContractor]: 'Window & Door Contractor',
    [SubcontractorCategory.GeneralContractor]: 'General Contractor',
    [SubcontractorCategory.MechanicalContractor]: 'Mechanical Contractor',
    [SubcontractorCategory.RoofingContractor]: 'Roofing Contractor',
    [SubcontractorCategory.TAB]: 'TAB',
  };

  /**
   * Converts a certain subcontractor category to readable title.
   * @param value Subcontractor category.
   */
  export function toReadable(value: SubcontractorCategory): string {
    return TO_TITLE_MAP[value];
  }

  /** Gets list of all subcontractor categories. */
  export function toArray(): SubcontractorCategory[] {
    return enumToArray(SubcontractorCategory);
  }

  /** Gets of select options list. */
  export function toSelectOptions(): OptionSelect<SubcontractorCategory>[] {
    return toArray()
      .map(category => ({
        label: SubcontractorCategory.toReadable(category),
        value: category,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }
}
